import classNames from 'classnames';
import { observer } from 'mobx-react';
import type { FunctionComponent } from 'react';
import type { Control } from 'react-hook-form';
import { useFormState } from 'react-hook-form';
import { Button } from '../../../components/button/button';
import type { SubmitFragment } from '../../../graphql/fragments/SubmitFragment.graphql';
import { progressStore } from '../../../lib/store/ui/progress';
import { RichText } from '../../rich-text';
import styles from './submit.module.scss';

/**
 * Renders a submit element with disclaimer.
 * This component automatically knows when to show spinner and disable the button.
 */
export const Submit: FunctionComponent<Props> = observer(({
  disclaimer,
  control,
  buttonText,
  analyticsKey,
  className,
  order = 'default',
  disabled = false
}) => {
  const {
    isSubmitting
  } = useFormState({
    control
  });
  const loading = progressStore.busy || isSubmitting;
  const nodes = [disclaimer?.text && <RichText key='disclaimer' source={disclaimer.text} renderers={{
    paragraph: (_, children) => <p data-testid='form.disclaimer' className={classNames(styles.disclaimer, styles[`disclaimer-${disclaimer.preset}`])}>
                                {children}
                            </p>
  }} />, <Button key='button' className={styles.button} analyticsKey={analyticsKey} type='submit' preset='primary' size='medium' disabled={disabled || loading} loading={loading} data-testid='form.submit'>
                {buttonText}
            </Button>];
  return <footer className={classNames(styles.submit, className)}>
                {order === 'reverse' ? nodes.reverse() : nodes}
            </footer>;
});
type Props = Omit<SubmitFragment, 'sys'> & {
  /**
   * Controls the form state, react-hook-form docs
   */
  control: Control<any>;

  /**
   * Extra class name for extra styling
   */
  className?: string;

  /**
   * If you pass 'reverse', the order will be [button, disclaimer]
   */
  order?: 'default' | 'reverse';

  /**
   * If you pass true, the button will be disabled
   * */
  disabled?: boolean;
};